import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Infopage from "../components/templates/Infopage"
import SEO from "../components/atoms/Seo"
import Title from "../components/atoms/Title"
import Divider from '../components/atoms/Divider'
import Box from '../components/atoms/Box'
import Grid from '../components/atoms/Grid'
import GridItem from '../components/atoms/GridItem'
import PressDate from '../components/atoms/PressDate'
import ImagePress from '../components/atoms/images/ImagePress'

const PressPage = () => {
    const data = useStaticQuery(graphql`
        query PressData {
            calvinayre: file(relativePath: { eq: "press/calvin-ayre.png" }) {
                childImageSharp {
                  fluid(maxWidth: 280) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
            casinosummit: file(relativePath: { eq: "press/IsmailCasinoSummit.png" }) {
                childImageSharp {
                  fluid(maxWidth: 280) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
            casinobeats: file(relativePath: { eq: "press/casinobeats.png" }) {
                childImageSharp {
                  fluid(maxWidth: 180) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
            disruptionismail: file(relativePath: { eq: "press/disruption_ismail.png" }) {
                childImageSharp {
                  fluid(maxWidth: 280) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
            eventusinternational: file(relativePath: { eq: "press/AGAMEABOVE-Eventus-International.png" }) {
                childImageSharp {
                  fluid(maxWidth: 280) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
            lasvegas: file(relativePath: { eq: "press/las-vegas.png" }) {
                childImageSharp {
                  fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
            igamingtimes: file(relativePath: { eq: "press/igamingtimes.png" }) {
                childImageSharp {
                  fluid(maxWidth: 280) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
            posteraward: file(relativePath: { eq: "press/poster-award.png" }) {
                childImageSharp {
                  fluid(maxWidth: 280) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
            sbcawards2020: file(relativePath: { eq: "press/SBC-Socialmedia-All-Portrait.png" }) {
                childImageSharp {
                  fluid(maxWidth: 280) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
            ceopress: file(relativePath: { eq: "press/ceo-press.jpeg" }) {
                childImageSharp {
                  fluid(maxWidth: 280) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
            agaegrkinder: file(relativePath: { eq: "press/AGA-EGR-Kinder.jpeg" }) {
                childImageSharp {
                  fluid(maxWidth: 280) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
            paulgirvanjoins: file(relativePath: { eq: "press/Paul_Girvan_joins.png" }) {
                childImageSharp {
                  fluid(maxWidth: 280) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
            egrwinner: file(relativePath: { eq: "press/egr-winner.png" }) {
                childImageSharp {
                  fluid(maxWidth: 280) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
            bwgstrategy: file(relativePath: { eq: "press/bwgstrategy.png" }) {
                childImageSharp {
                  fluid(maxWidth: 280) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
        }
    `)

    return (
    <Infopage
        title='PRESS'
        headerColor='Blue'
        leadText='A GAME ABOVE regularly contribute to press and publications across marketing and customer experience for sports betting, casino gaming, iGaming, lottery and gambling industry matters.'
        icon="press"
        iconColor='white'
        iconBackground='#A2845C'>
        <SEO 
            title='Press releases and news about A GAME ABOVE'
            description="A GAME ABOVE regularly contribute to news, press and publications across marketing, branding, customer experience, betting & gaming industry matters."
            keywords="marketing agency, experience marketing agency, igaming marketing agency, igaming marketing, gambling marketing, casino marketing, omni channel marketing, online betting marketing, sales promotions agency, ismail vali, paul girvan, game above, a game above, marketing, igaming, promos, campaigns"
        />

        <Divider />
        <Box color='white'>
            <Grid>
                <GridItem size='20%'>
                    <PressDate>June 2022</PressDate>
                </GridItem>
                <GridItem size='25%' style={{margin: 'auto 0'}}>
                    <ImagePress image={data.bwgstrategy.childImageSharp.fluid} alt='A GAME ABOVE EGR BWG Strategy' />
                </GridItem>
                <GridItem size='54%'>
                    <Title level={3} position='left'>A GAME ABOVE Founder speaks on casino and sports betting with BWG Strategy:</Title>
                    <p>
                    AGA Founder Ismail Vali will be speaking with Brad Lyons, Head of Primary Research for BWG Strategy on June 9 at 10.30am Eastern time for a detailed discussion across US marketing and performance in the iGaming, gambling and sports betting sectors.
                    Join the call for free by registering <a href="https://bwgstrategy.com/bwg-event/live-casino-sports-betting-qa-2/" target="_blank" rel="noopener noreferrer">here</a>
                    </p>
                </GridItem>
            </Grid>
        </Box>
        <Box color='white'>
            <Grid>
                <GridItem size='20%'>
                    <PressDate>June 2021</PressDate>
                </GridItem>
                <GridItem size='25%' style={{margin: 'auto 0'}}>
                    <ImagePress image={data.egrwinner.childImageSharp.fluid} alt='A GAME ABOVE EGR Campaign of the Year Award' />
                </GridItem>
                <GridItem size='54%'>
                    <Title level={3} position='left'>A GAME ABOVE wins Campaign of the Year award:</Title>
                    <p>
                    Marketing supplier A GAME ABOVE are pleased to win the prestigious Campaign of the Year award at the #egrmarketingandinnovationawards
                    Our thanks to eGR - an amazing achievement for the team and our commitment to clients and customer experience. Against an impressive shortlist, we're humbled to have succeeded with an innovative, customer-focused campaign that produced record results for our client and delighted players.
                    </p>
                </GridItem>
            </Grid>
        </Box>
        <Box color='white'>
            <Grid>
                <GridItem size='20%'>
                    <PressDate>June 2021</PressDate>
                </GridItem>
                <GridItem size='25%' style={{margin: 'auto 0'}}>
                    <ImagePress image={data.paulgirvanjoins.childImageSharp.fluid} alt='A GAME ABOVE Paul Girvan' />
                </GridItem>
                <GridItem size='54%'>
                    <Title level={3} position='left'>Industry veteran Paul Girvan joins A GAME ABOVE:</Title>
                    <p>
                        Girvan will introduce and develop relationships with tribal gaming operations, 
                        commercial gaming brands and B2B gaming suppliers and manufacturers.
                    </p>
                    <a href="https://www.igbnorthamerica.com/a-game-above-appoints-girvan-to-senior-partner-marketing-role/" target="_blank" rel="noopener noreferrer">Read more</a>
                </GridItem>
            </Grid>
        </Box>
        <Box color='white'>
            <Grid>
                <GridItem size='20%'>
                    <PressDate>May 2021</PressDate>
                </GridItem>
                <GridItem size='25%' style={{margin: 'auto 0'}}>
                    <ImagePress image={data.agaegrkinder.childImageSharp.fluid} alt='A GAME ABOVE nominated for 3 eGR Awards' />
                </GridItem>
                <GridItem size='54%'>
                    <Title level={3} position='left'>AGA nominated for 3 eGR Awards:</Title>
                    <p>
                        - Acquisition & Retention Partner of the Year at the #EGRNorthAmericaAwards<br />
                        - Acquisition & Retention Partner of the Year AND Marketing & PR Supplier of the Year at the #EGRB2BAwards
                    </p>
                    <a href="https://www.linkedin.com/posts/activity-6796440611944722432-C-of/" target="_blank" rel="noopener noreferrer">Read more</a>
                </GridItem>
            </Grid>
        </Box>
        <Box color='white'>
            <Grid>
                <GridItem size='20%'>
                    <PressDate>March 2021</PressDate>
                </GridItem>
                <GridItem size='25%' style={{margin: 'auto 0'}}>
                    <ImagePress image={data.ceopress.childImageSharp.fluid} alt='A GAME ABOVE NEW CEO NICOLAS FLEIDERMAN' />
                </GridItem>
                <GridItem size='54%'>
                    <Title level={3} position='left'>Nicolas Fleiderman joins A GAME ABOVE as CEO:</Title>
                    <p>
                    A GAME ABOVE, the leading customer experience campaigns and consulting agency specialising in work for operator and supplier clients across regulated iGaming markets, are proud to announce the appointment of Nicolas Fleiderman as Chief Executive Officer.
                    </p>
                    <a href="https://egr.global/marketing/news/exclusive-a-game-above-appoints-nicolas-fleiderman-as-new-ceo/" target="_blank" rel="noopener noreferrer">Read press release</a>
                </GridItem>
            </Grid>
        </Box>
        <Box color='white'>
            <Grid>
                <GridItem size='20%'>
                    <PressDate>November 2020</PressDate>
                </GridItem>
                <GridItem size='25%' style={{margin: 'auto 0'}}>
                    <ImagePress image={data.sbcawards2020.childImageSharp.fluid} alt='THREE SBC AWARDS NOMINATIONS A GAME ABOVE' />
                </GridItem>
                <GridItem size='54%'>
                    <Title level={3} position='left'>SHORTLISTED FOR 3 SBC AWARDS:</Title>
                    <p>
                    Very proud to highlight A GAME ABOVE are shortlisted for 3 awards across marketing, advisory and leadership at the upcoming SBC Awards 2020.
                    Work with us to help make your customers believe...
                    </p>
                    <a href="mailto:info@agameabove.com">info@aGameAbove.com</a>
                </GridItem>
            </Grid>
        </Box>
        <Box color='white'>
            <Grid>
                <GridItem size='20%'>
                    <PressDate>July 2020</PressDate>
                </GridItem>
                <GridItem size='25%' style={{margin: 'auto 0'}}>
                    <ImagePress image={data.disruptionismail.childImageSharp.fluid} alt='Ismail Vali - DISRUPTION MOMENT WEBINAR, A GAME ABOVE' />
                </GridItem>
                <GridItem size='54%'>
                    <Title level={3} position='left'>DISRUPTION MOMENT WEBINAR:</Title>
                    <p>
                        <a href="https://youtu.be/LFDSeJ8Zkug" target="_blank" rel="noopener noreferrer">
                        CPMO Ismail Vali talks about our customer experience obsession
                        </a>
                    </p>
                </GridItem>
            </Grid>
        </Box>
        <Box color='white'>
            <Grid>
                <GridItem size='20%'>
                    <PressDate>July 2020</PressDate>
                </GridItem>
                <GridItem size='25%' style={{margin: 'auto 0'}}>
                    <ImagePress image={data.casinosummit.childImageSharp.fluid} alt='Ismail Vali Casino Summit, A GAME ABOVE' />
                </GridItem>
                <GridItem size='54%'>
                    <Title level={3} position='left'>SPORTS BETTING & CASINO SUMMIT NORTH AMERICA:</Title>
                    <p>
                        <a href="https://summits.gamingamericas.com/" target="_blank" rel="noopener noreferrer">
                            Ismail Vali, Chief Product & Marketing Officer for A GAME ABOVE speaks on gaming industry innovation
                        </a>
                    </p>
                </GridItem>
            </Grid>
        </Box>
        <Box color='white'>
            <Grid>
                <GridItem size='20%'>
                    <PressDate>July 2020</PressDate>
                </GridItem>
                <GridItem size='25%' style={{margin: 'auto 0'}}>
                    <ImagePress image={data.posteraward.childImageSharp.fluid} alt='A GAME ABOVE NOMINATED FOR 3 eGR B2B AWARDS' />
                </GridItem>
                <GridItem size='54%'>
                    <Title level={3} position='left'>A GAME ABOVE NOMINATED FOR 3 eGR B2B AWARDS:</Title>
                </GridItem>
            </Grid>
        </Box>
        <Box color='white'>
            <Grid>
                <GridItem size='20%'>
                    <PressDate>July 2020</PressDate>
                </GridItem>
                <GridItem size='25%' style={{margin: 'auto 0'}}>
                    <ImagePress image={data.lasvegas.childImageSharp.fluid} alt='A GAME ABOVE LAS VEGAS LESSON' />
                </GridItem>
                <GridItem size='54%'>
                    <Title level={3} position='left'>Eventus International Webinar:</Title>
                    <p>
                        <a href="https://www.eventus-international.com/the-las-vegas-lesson" target="_blank" rel="noopener noreferrer">
                            Eventus International Webinar: <br />
                            The Las Vegas Lesson: How to make marketing work with product and calendar challenges. <br />
                            Watch the webinar on demand and download PDF slide deck. <br />
                        </a>
                    </p>
                </GridItem>
            </Grid>
        </Box>
    </Infopage>
    )
}

export default PressPage