import React from "react";
import styled from "styled-components"

const StyledContainer = styled.div`
    font-size: 24px;
    font-family: 'Raleway';
    font-weight: 900;
    text-align: center;
    margin: 16px 0;
    width: 100%;

    @media only screen and (min-width: 950px) {
        transform: rotate(90deg);
        margin: 96px 0;
    }
`;

const PressDate = ({children, size, gridOrder, ...props}) => {
    return (
        <StyledContainer size={size} gridOrder={gridOrder}>
            {children}
        </StyledContainer>
    )
}

export default PressDate