import React from "react"
import Img from "gatsby-image"

const ImagePress = ({image, alt, ...props}) => {
  return(
    <div style={{ width: '100%', display: 'block', maxWidth: '200px', margin: '16px auto' }} {...props}>
        <Img fluid={image} alt={alt} />
    </div>
  )
}

export default ImagePress
